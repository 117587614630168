import getDateFromString from "../utils/getDateFromString"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"

function Forecast({weatherData, setSeletedDate}) {
    return (
        <div className="forecast">
            {weatherData?.nineDayForecast?.weatherForecast.map((eachDay, index) => (
                <div className="forecast-card" key={index}>
                    <CardContent
                        onMouseEnter={() => {
                            setSeletedDate(getDateFromString(eachDay.forecastDate))
                        }}
                        onMouseLeave={() => {
                            setSeletedDate(new Date())
                        }}
                    >
                        <Typography sx={{ fontSize: 14 }} color="warning.main" gutterBottom>
                            {getDateFromString(eachDay.forecastDate).toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })}
                        </Typography>
                        <Typography variant="h5" component="div">
                            {getDateFromString(eachDay.forecastDate).toLocaleString("en", { weekday: "short" })}
                        </Typography>
                        <img src={`https://www.hko.gov.hk/images/HKOWxIconOutline/pic${eachDay.ForecastIcon}.png`} alt="daily-forecast" />
                        <Typography sx={{ mb: 1.5 }} color="warning.main">
                            {eachDay.forecastMintemp.value} | {eachDay.forecastMaxtemp.value} °C
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="warning.main">
                            {eachDay.forecastMinrh.value} | {eachDay.forecastMaxrh.value} %
                        </Typography>
                        <Typography variant="body2">
                            {eachDay.forecastWeather}
                        </Typography>
                    </CardContent>
                </div>
            ))}
        </div>
    )
}

export default Forecast