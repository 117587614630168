import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, registerables as registerablesJS } from "chart.js"
import { Doughnut } from "react-chartjs-2";
ChartJS.register(...registerablesJS)
ChartJS.register(ArcElement, Tooltip, Legend, Title)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#fff"
      }
    },
    title: {
      display: true,
      text: "Temperature Distribution",
      color: "#fff"
    },
    tooltip: {
      callbacks: {
        label: function(context){
          var label = context.label,
              currentValue = context.raw,
              total = context.chart._metasets[context.datasetIndex].total;

          var percentage = parseFloat((currentValue/total*100).toFixed(1));

          return label + ": " +currentValue + ' (' + percentage + '%)';
        }
      }
    }
  },
}

export default function TempDoughnutChart({ tempChart }) {
  const tempDist = tempChart.tempDist
  const data = {
    labels: ["Above 30°C", "25-30°C", "20-25°C", "15-20°C", "10-15°C", "Below 10°C"],
    datasets: [
      {
        label: "# of Votes",
        data: tempDist,
        backgroundColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  return <Doughnut data={data} options={options} />
}
