import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import decodeHTML from "../utils/decodeHTML";

function SelectMenu({title, value, label, onChange, data}) {

    return (
        <FormControl sx={{ m: 1, minWidth: 180 }}>
            <InputLabel id="select-district-label">{title}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={onChange}
                labelId="select-district-label"
                id="select-district"
                autoWidth
            >
                {data?.data.map((location, index) => (
                    <MenuItem key={index} value={index}>{decodeHTML(location.place)}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectMenu