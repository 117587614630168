import TideChart from "./TideChart"

function Tidal({ selectedDate, tideChart, tideTable }) {
    return (
        <>
            <div className="chart">
                <h2>{selectedDate.toLocaleString("en", { weekday: "long" })}, {selectedDate.toLocaleString("default", { day: "numeric", month: "long", year: "numeric" })}</h2>
                <TideChart tideChart={tideChart} />
            </div>
            <div className="table">
                <table>
                    <tbody>
                        <tr>
                            <th>Tide</th>
                            <th>Time</th>
                        </tr>
                        {tideTable}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Tidal