import React, { useRef } from "react"
import { Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, Title, registerables as registerablesJS } from "chart.js"
import { Chart } from "react-chartjs-2"
ChartJS.register(...registerablesJS)
ChartJS.register(LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Title, Tooltip)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#fff"
      }
    },
    title: {
      display: true,
      text: "Maximum, Minimum & Average Temperature (°C)",
      color: "#fff"
    },
  },
}

export default function TempLineChart({ tempChart }) {
  const labels = tempChart.dates
  const chartRef = useRef(null)
  const data = {
    labels,
    datasets: [
      {
        label: "Mininum",
        data: tempChart.minTemp,
        borderColor: "#267EFF",
        backgroundColor: "#267EFF",
        hoverBorderColor: "#fff",
        lineTension: 0.4,
      },
      {
        label: "Average",
        data: tempChart.avgTemp,
        borderColor: "#26FF3A",
        backgroundColor: "#26FF3A",
        hoverBorderColor: "#fff",
        lineTension: 0.4,
      },
      {
        label: "Maximum",
        data: tempChart.maxTemp,
        borderColor: "#FFA726",
        backgroundColor: "#FFA726",
        hoverBorderColor: "#fff",
        lineTension: 0.4,
      },
    ],
  }

  return <Chart ref={chartRef} type="line" data={data} options={options} width={550} height={350} />
}
