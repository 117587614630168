import SelectMenu from "./SelectMenu"

function Sun({ weatherData, rainDistrict, setRainDistrict }) {
  return (
    <div className="deck">
      <SelectMenu
        title="Rain Level"
        data={weatherData?.currentWeather?.rainfall}
        value={rainDistrict}
        label="rainDistrict"
        onChange={(event) => { setRainDistrict(event.target.value) }}
        autoWidth
      />
      <div className="deck-cards">
        <div className="deck-card">
          <span className="card-name">
            UV Index
          </span>
          <span className={weatherData?.currentWeather?.uvindex.data && weatherData?.currentWeather?.uvindex.data[0].value > 5 ? "card-value-warn" : "card-value"}>
            {weatherData?.currentWeather?.uvindex === "" ? "0" : weatherData?.currentWeather?.uvindex.data[0]?.value}
          </span>
        </div>
        <div className="deck-card">
          <span className="card-name">
            UV Exposure
          </span>
          <span className={weatherData?.currentWeather?.uvindex.data && weatherData?.currentWeather?.uvindex.data[0].value > 5 ? "card-value-warn" : "card-value"}>
            {weatherData?.currentWeather?.uvindex === "" ? "None" : weatherData?.currentWeather?.uvindex.data[0]?.desc[0].toUpperCase() + weatherData?.currentWeather?.uvindex.data[0]?.desc.substring(1)}
          </span>
        </div>
        <div className="deck-card">
          <p className="card-name">
            Humidity
          </p>
          <p className="card-value">
            {weatherData?.currentWeather?.humidity.data[0].value}%
          </p>
        </div>
        <div className="deck-card">
          <span className="card-name">
            Rainfall
          </span>
          <span className="card-value">
            {weatherData?.currentWeather?.rainfall.data[rainDistrict].max} mm
          </span>
        </div>
      </div>
    </div>
  )
}

export default Sun