import SelectMenu from "./SelectMenu"

function Hero({ weatherData, tempDistrict, setTempDistrict }) {

    return (
        <div className="hero">
            <SelectMenu
                title="Temp Level"
                data={weatherData?.currentWeather?.temperature}
                value={tempDistrict}
                label="tempDistrict"
                onChange={(event) => { setTempDistrict(event.target.value) }}
                autoWidth
            />
            <div className="hero-wave -one"></div>
            <div className="hero-wave -two"></div>
            <div className="hero-wave -three"></div>
            <div className="hero-img"><img src={`https://www.hko.gov.hk/images/HKOWxIconOutline/pic${weatherData?.currentWeather?.icon}.png`} alt="current-forecast" /></div>
            <div className="hero-info">
                <h2 className="hero-location">HONG KONG</h2>
                <h2 className="hero-district">{weatherData?.currentWeather?.temperature.data[tempDistrict].place}</h2>
                <h2 className="hero-temp">{weatherData?.currentWeather?.temperature.data[tempDistrict].value}°C</h2>
                <p className="hero-temp-water">{weatherData?.nineDayForecast?.seaTemp.value}°C water</p>
            </div>
        </div>
    )
}

export default Hero