import { useState, useEffect } from "react"
import Hero from "./components/Hero"
import Tidal from "./components/Tidal"
import Sun from "./components/Sun"
import Forecast from "./components/Forecast"
import TempDeck from "./components/TempDeck"
import getDayOfYear from "./utils/getDayOfYear"
import get12HourTime from "./utils/get12HourTime"
import CircularProgress from '@mui/material/CircularProgress';
import "./App.css"

function App() {
  const [selectedDate, setSeletedDate] = useState(new Date())
  const [weatherData, setWeatherData] = useState()
  const [tempDistrict, setTempDistrict] = useState(0)
  const [rainDistrict, setRainDistrict] = useState(0)
  const [tideTable, setTideTable] = useState()
  const [tideChart, setTideChart] = useState()

  useEffect(() => {
    // Fetch Current Weather, 9-Day Forecast, High Low Tides, and Hourly Tides from HKO API
    async function fetchWeatherData() {
      const currentWeather = await fetch("https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=en")
        .then(res => res.json())

      const nineDayForecast = await fetch("https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=fnd&lang=en")
        .then(res => res.json())

      const highLowTides = await fetch("https://data.weather.gov.hk/weatherAPI/opendata/opendata.php?dataType=HLT&station=QUB&year=2022&rformat=json")
        .then(res => res.json())

      const hourlyTides = await fetch("https://data.weather.gov.hk/weatherAPI/opendata/opendata.php?dataType=HHOT&station=QUB&year=2022&rformat=json")
        .then(res => res.json())

      setWeatherData(
        {
          currentWeather,
          nineDayForecast,
          highLowTides,
          hourlyTides
        })
    }
    fetchWeatherData()
  }, [])

  useEffect(() => {
    // Prepare tide data for table and chart
    if (weatherData) {
      const tableArray = []
      const chartArray = []
      const tideTableData = weatherData?.highLowTides?.data[getDayOfYear(selectedDate) - 1]
      const tideChartData = weatherData?.hourlyTides?.data[getDayOfYear(selectedDate) - 1]

      for (let i = 2; i < 10; i += 2) {
        if (tideTableData[i]) {
          tableArray.push(
            <tr>
              <td>{tideTableData[i + 1] + " m"}</td>
              <td>{get12HourTime(tideTableData[i])}</td>
            </tr>
          )
        }
      }

      for (let i = 2; i < 26; i++) {
        chartArray.push(tideChartData[i])
      }
      setTideTable(tableArray)
      setTideChart(chartArray)
    }

  }, [selectedDate, weatherData])

  // Loading bar
  if (!weatherData) {
    return (
      <div className="body">
        <CircularProgress color="warning" />
      </div>
    )
  }

  return (
    <div className="body">
      <div className="current-weather">
        <Hero
          weatherData={weatherData}
          tempDistrict={tempDistrict}
          setTempDistrict={setTempDistrict}
        />
        <Tidal
          selectedDate={selectedDate}
          tideChart={tideChart}
          tideTable={tideTable}
        />
        <Sun 
          weatherData={weatherData}
          rainDistrict={rainDistrict}
          setRainDistrict={setRainDistrict}
        />
      </div>
      <Forecast 
        weatherData={weatherData}
        setSeletedDate={setSeletedDate}
      />
      <TempDeck />
    </div>
  );
}

export default App;
