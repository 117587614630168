import React, { useRef, useEffect, useState } from "react"
import { Chart as ChartJS, Title, LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, registerables as registerablesJS } from "chart.js"
import { Chart } from "react-chartjs-2"
ChartJS.register(...registerablesJS)
ChartJS.register(LinearScale, Title, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip)

const labels = ["12 AM", "1 AM", "2 AM", "3 AM", "4 AM", "5 AM", "6 AM", "7 AM", "8 AM", "9 AM", "10 AM", "11 AM", "12 PM", "1 PM", "2 PM", "3 PM", "4 PM", "5 PM", "6 PM", "7 PM", "8 PM", "9 PM", "10 PM", "11 PM"]

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#fff"
      }
    },
    title: {
      display: true,
      text: "24 Hours Predicted Tides at Quarry Bay",
      color: "#fff"
    },
  },
};

function createGradient(ctx, area) {
  // Pick chart colors
  const colorStart = "red"
  const colorMid = "orange"
  const colorEnd = "yellow"

  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top)

  gradient.addColorStop(0, colorStart)
  gradient.addColorStop(0.5, colorMid)
  gradient.addColorStop(1, colorEnd)

  return gradient
}

export default function TideChart({ tideChart }) {
  const chartRef = useRef(null)
  const [chartData, setChartData] = useState({ datasets: [], })
  const data = {
    labels,
    datasets: [
      {
        label: "Tide Height (m)",
        data: tideChart
      },
    ],
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map(dataset => ({
        ...dataset,
        borderColor: createGradient(chart.ctx, chart.chartArea),
      })),
    }

    setChartData(chartData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tideChart])

  return <Chart ref={chartRef} type="line" options={options} data={chartData} width={550} height={340} />
}
