import * as React from "react"
import { useState, useEffect } from "react"
import TempLineChart from "./TempLineChart"
import TempDoughnutChart from "./TempDoughnutChart"
import getDateFromArray from "../utils/getDateFromArray"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"

function TempDeck() {
    const [tempData, setTempData] = useState()
    const [filteredData, setFilteredData] = useState({
        dates: [],
        minTemp: [],
        maxTemp: [],
        avgTemp: [],
        tempDist: [0, 0, 0, 0, 0, 0],
    })
    const [startDate, setStartDate] = React.useState(new Date("2021-01-01"))
    const [endDate, setEndDate] = React.useState(new Date("2021-03-31"))
    const tableData = []

    useEffect(() => {
        // Fetch Historical Temperature Data from HKO API
        async function fetchTempData() {
            const minTemp = await fetch("https://data.weather.gov.hk/weatherAPI/opendata/opendata.php?dataType=CLMMINT&rformat=json&station=CCH")
                .then(res => res.json())

            const maxTemp = await fetch("https://data.weather.gov.hk/weatherAPI/opendata/opendata.php?dataType=CLMMAXT&rformat=json&station=CCH")
                .then(res => res.json())

            setTempData(
                {
                    minTemp: minTemp.data,
                    maxTemp: maxTemp.data
                })
        }
        fetchTempData()
    }, [])

    useEffect(() => {
        // Filter temperature data from Datepicker selection
        const filteredResults = {
            dates: [],
            minTemp: [],
            maxTemp: [],
            avgTemp: [],
            tempDist: [0, 0, 0, 0, 0, 0],
        }

        for (let i = 0; i < tempData?.minTemp.length; i++) {
            const recordDate = getDateFromArray(tempData.minTemp[i])
            const avgTemp = (Number(tempData.minTemp[i][3]) + Number(tempData.maxTemp[i][3])) / 2

            if (startDate <= recordDate && endDate >= recordDate) {
                filteredResults.dates.push(getDateFromArray(tempData.minTemp[i]).toLocaleDateString())
                filteredResults.minTemp.push(tempData.minTemp[i][3])
                filteredResults.maxTemp.push(tempData.maxTemp[i][3])
                filteredResults.avgTemp.push(avgTemp.toFixed(1))

                if (avgTemp > 30) {
                    filteredResults.tempDist[0]++
                } else if (avgTemp > 25) {
                    filteredResults.tempDist[1]++
                } else if (avgTemp > 20) {
                    filteredResults.tempDist[2]++
                } else if (avgTemp > 15) {
                    filteredResults.tempDist[3]++
                } else if (avgTemp > 10) {
                    filteredResults.tempDist[4]++
                } else {
                    filteredResults.tempDist[5]++
                }
            }
        }
        setFilteredData(filteredResults)
    }, [tempData, startDate, endDate])

    // Push filtered results into table
    for (let i = 0; i < filteredData?.dates.length; i++) {
        tableData.push(
            <tr>
                <td>{filteredData.dates[i]}</td>
                <td>{filteredData.minTemp[i]}</td>
                <td>{filteredData.maxTemp[i]}</td>
                <td>{filteredData.avgTemp[i]}</td>
            </tr>
        )
    }

    return (
        <div className="filter">
            <div className="filter-table">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <h3>Historical Temperature (°C)</h3>
                    <Stack spacing={3} direction="row" justifyContent="center">
                        <DesktopDatePicker
                            name="start"
                            label="Start Date"
                            inputFormat="MM/dd/yyyy"
                            value={startDate}
                            onChange={(newValue) => { setStartDate(newValue) }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <DesktopDatePicker
                            name="end"
                            label="End Date"
                            inputFormat="MM/dd/yyyy"
                            value={endDate}
                            onChange={(newValue) => { setEndDate(newValue) }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Stack>
                    <div className="tempTable">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Date</th>
                                    <th>Minimum</th>
                                    <th>Maximum</th>
                                    <th>Average</th>
                                </tr>
                                {tableData}
                            </tbody>
                        </table>
                    </div>
                </LocalizationProvider>
            </div>
            <div className="filter-chart">
                <TempLineChart tempChart={filteredData} />
                <div className="doughnut">
                    <TempDoughnutChart tempChart={filteredData} />
                </div>
            </div>
        </div>
    )
}

export default TempDeck